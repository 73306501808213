::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #aaa;
}

::-webkit-scrollbar-track:hover {
  background-color: #f2f2f2;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

@media (max-width: 720px) {
  pre {
    width: calc(100vw - 110px);
  }
}

pre:has(div.codeblock) {
  padding: 0;
}

.bg-light2 {
  background-color: #5aad95;
  color: white;
}

.message-container {
  background-color: white;
  border: 1px solid rgb(192, 192, 192);
  border-radius: 4px;
  -webkit-box-flex: 1;
  flex-grow: 1;
  margin: 0px 5px;
  min-width: 200px;
  display: flex;
  flex-direction: column;
  -webkit-box-pack: justify;
  justify-content: space-between;
  flex-shrink: 0;
  margin: auto;
}

.message-container .message-content {
  display: flex;
  flex-direction: column;
  height: calc(100% - max(11rem, 15%));
  overflow-y: scroll;
}

.rcw-conversation-container {
  width: 500px;
}

.rcw-response {
  width: 100%;
}

.rcw-response .rcw-message-text {
  max-width: calc(min(800px, 80%)) !important;
}

.message-container .answer-message {
  border-top: 1px solid rgba(34, 36, 38, 0.15);
  border-bottom: 1px solid rgba(34, 36, 38, 0.15);
  background-color: rgba(247, 247, 248, 1);
}

.message-container .message-sender-container {
  display: flex;
  flex-direction: column;
  height: max(11rem, 15%);
}

.message-container .message-sender {
  padding: 0.5rem;
  border-top: 1px solid rgb(192, 192, 192);
}

.message-sender-container .bottom-content {
  flex-shrink: 0;
  height: 2rem;
}

.fix-page-height {
  height: 100% !important;
  overflow-y: hidden !important;
  width: 100% !important;
  overflow-x: hidden !important;
}

.no-display {
  display: none !important;
}

.chip {
  box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.5);
  padding: 5px 10px;
  display: inline-block;
  line-height: 1;
  vertical-align: baseline;
  border-radius: 4px;
  transition: transform 0.3s, box-shadow 0.3s;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  /* Prevent chip text from wrapping */
  border: 1px solid rgba(34, 36, 38, 0.15);
  font-weight: bold;
  /* background-color: radial-gradient(circle, #4ea8f1 0, #397de1 50%, #0b50b5 100%); */
  background: #202123;
  margin-right: 0.25rem;
  margin-bottom: 0.25rem;
}

.chips-bar {
  text-align: center;
  font-size: 16px;
}

/* Style for desktop hover */
@media (min-width: 768px) {
  .chip:hover {
    box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.5);
    transform: translateY(-2px);
  }

  .chip {
    padding: 10px 20px;
  }

  .chips-bar {
    font-size: 18px;
  }

  .selected-chip::before {
    font-size: x-large !important;
  }

  .message-container .message-sender {
    padding: 1rem;
  }

  .message-container .message-content {
    height: calc(100% - max(13rem, 15%));
  }

  .message-container .message-sender-container {
    height: max(13rem, 15%);
  }
}

.unselected-chip {
  color: white;
  box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.5);
  opacity: 0.6;
}

.selected-chip {
  transform: translateY(1px);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
  color: #ffffff;
}

.selected-chip::before {
  content: '✓';
  font-size: medium;
  position: absolute;
  top: 0;
  left: 0;
  color: #02ff02;
}

.border-top {
  border-top: 1px solid black;
}

.flex-no-shrink {
  flex-shrink: 0;
}

.wide-column {
  min-width: 5rem;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

.pulse {
  animation: pulse 2s infinite;
}

.expand-icon {
  border: 1px solid rgba(92, 92, 92, 0.205) !important;
  border-radius: 4px !important;
}

.expand-icon:hover {
  background: #ccc;
}

.grid-tem {
  min-width: 10rem;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: var(--grid-gap, 1.5rem);
  /* Default grid gap value */
}

.feature-home {
  display: block;
}

.feature-home-item {
  text-align: left;
  max-width: 360px;
  margin: auto;
}

.column-item {
  display: block !important;
  margin: 0 auto;
}

@media screen and (min-width: 1100px) {
  .grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: var(--grid-gap, 1.5rem);
    /* Default grid gap value */
  }

  .message-container .message-sender {
    padding: 1rem 2rem 1rem 2rem;
    border-top: 1px solid rgb(192, 192, 192);
  }

  .feature-home {
    display: flex;
    margin: 4rem auto;
    gap: 2rem;
    justify-content: center;
  }

  .feature-home-item {
    text-align: left;
    max-width: 360px;
  }
}

.warn-text {
  padding: 10px;
  border: none;
  border-radius: 6px;
  font-size: large;
  background-color: #ececec;
  margin-top: 1rem;
  display: inline-block;
  min-height: 75px;
}

button.warn-text:hover {
  background-color: #cecccc;
}

button:disabled,
button[disabled] {
  background-color: #ccc !important;
  color: #666666 !important;
  cursor: not-allowed !important;
}

.w-prewrap {
  white-space: pre-wrap;
}

.right-align {
  margin-left: auto !important;
}

.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}

.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.policy a {
  color: #8e9095;
  text-decoration: underline;
}

.policy a:hover {
  color: #8e9095;
  text-decoration: none;
}

.chat-message {
  white-space: pre-wrap;
  word-wrap: break-word;
  max-width: calc(100% - 64px);
}

.policy {
  padding: 40px 0;
  line-height: 1.6;
}

.policy {
  /* background-image: linear-gradient(-180deg,#30353e 30%,#4d76ba 90%); */
  padding: 40px 0;
  line-height: 1.6;
}

.policy h1 {
  margin: 50px 0 10px;
  text-align: center;
}

.policy p {
  text-align: left;
}

.text-left {
  text-align: left !important;
}

.btn-primary {
  background: radial-gradient(circle, #4ea8f1 0, #397de1 50%, #0b50b5 100%);
}

.nav-link {
  color: white !important;
}

.inline-alert {
  padding: 6px !important;
  margin: 0 !important;
}

.alert-bar {
  height: 48px;
  background-color: lightyellow;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
}

.alert-bar .message-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.alert-bar .button {
  padding: 0.5rem 1rem;
  border: none;
  background-color: #fff;
  color: #000;
  font-size: 1.2rem;
  cursor: pointer;
  margin-left: 0.5rem;
}

.alert-bar .close {
  font-size: 1.5rem;
  cursor: pointer;
}

.button-link {
  background: none !important;
  border: none !important;
  color: #0d6efd !important;
  cursor: pointer !important;
}

.border-none {
  border: none !important;
}

.text-gray {
  color: #4b5563 !important;
}

.right-corner {
  top: 0;
  right: 0;
}

.action-success {
  opacity: 0;
  transition: opacity 0.2s cubic-bezier(0.17, 0.67, 0.83, 0.67) 0s;
  color: #4d76ba;
}

.m-auto {
  margin: auto;
}

.shadow-border {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5) !important;
}

.font-bold {
  font-weight: bold;
}

.box-div {
  display: block;
  align-items: center;
  justify-content: center;
  min-height: 4rem;
  width: 100%;
  border: none;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  border-radius: 1rem;
  padding: 1rem;
  cursor: pointer;
}

.box-div:hover {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.3);
}

.clamp-10 {
  display: -webkit-box;
  -webkit-line-clamp: 10;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.box-foot {
  margin-top: 0.5rem;
  text-align: right;
  font-size: 0.875rem;
  line-height: 1rem;
  color: black;
}

.modal-90w {
  width: 90% !important;
  max-width: 2000px !important;
}

.w-prewrap p {
  margin-bottom: 0.125rem !important;
}

.modal-title {
  color: #4b5563;
}

.font-sm {
  font-size: 0.875rem;
}

.title-field {
  display: block;
  width: calc(100% - 2rem);
  padding: 0.5rem;
  margin: 1rem;
  border: none;
  font-size: large;
  color: #0b50b5;
  box-sizing: border-box;
}

.title-field:hover {
  border: 1px solid #4b5563;
}

.title-field:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.2);
}

.dropdown-eclipsis {
  color: #4b5563;
  cursor: pointer;
}

.dropdown-menu.show {
  right: 0;
}

.transparent-button {
  border: none;
  background-color: transparent;
  padding: 10px 20px;
  transition: background-color 0.3s ease;
}

.transparent-button:hover {
  background-color: #4b5563;
}

.banner-wrapper {
  background-image: linear-gradient(180deg, #f6faff, #eaf3ff);
}

.banner-wrapper .container {
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
}

.feature-image {
  width: 100%;
}

@media (min-width: 1600px) {
  .container {
    max-width: 1460px !important;
  }
}

.diagnose-title {
  margin: 2rem 1rem;
}

.banner-wrapper .banner-title {
  color: #142132;
  padding: 0 20px;
  text-align: center;
  font-size: 26px;
  font-weight: 700;
  line-height: 30px;
}

.banner-wrapper .banner-subtitle {
  color: #3d434e;
  padding: 0 20px;
  text-align: center;
  text-align: center;
}

@media (min-width: 992px) {
  .banner-wrapper .container {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-top: 75px;
    position: relative;
    top: 15px;
  }

  .banner-wrapper .banner-title {
    color: #142132;
    font-size: 48px;
    font-weight: 700;
    line-height: 50px;
    margin-bottom: 30px;
    text-align: center;
  }

  .banner-wrapper .banner-subtitle {
    color: #3d434e;
    line-height: 27px;
    margin-bottom: 20px;
    text-align: center;
  }
}

@media (min-width: 768px) {
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
}

.arrow-right {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.faqs-wrapper {
  position: relative;
  width: 100%;
}

.faqs-wrapper .faqs-container {
  padding: 0;
}

.faqs-wrapper .faqs-title {
  color: #142132;
  font-size: 36px;
  font-weight: 600;
  letter-spacing: 2px;
  line-height: 54px;
  margin-top: 0;
  margin-bottom: 60px;
  text-align: center;
}

.faqs-wrapper .faqs-cards {
  width: 100%;
}

@media (min-width: 1400px) {
  .faqs-wrapper .faqs-cards-container {
    -webkit-column-gap: 40px;
    column-gap: 40px;
    display: flex;
    position: absolute;
  }

  .faqs-wrapper .faqs-cards-left,
  .faqs-wrapper .faqs-cards-right {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    row-gap: 40px;
  }

  .faqs-wrapper .footer-wrapper {
    margin-top: 500px;
  }

  .faqs-wrapper .faqs-card {
    margin-bottom: 0;
  }
}

.faqs-wrapper .faqs-card {
  background-color: #fff;
  border: 1px solid #dedede;
  border-radius: 40px;
  box-shadow: 0 20px 38px rgba(101, 122, 147, 0.1);
  z-index: 2;
  margin-bottom: 1rem;
}

.faqs-wrapper .faqs-card:hover {
  background-color: #dcdcdc;
}

.faqs-wrapper .accordion-header {
  cursor: pointer;
}

.faqs-wrapper .question-text {
  color: #142132;
  font-family: Poppins-Bold;
  font-size: 24px;
  font-weight: 700;
  line-height: 42px;
}

.faqs-wrapper .accordion-button {
  background: transparent !important;
  border-radius: 40px;
  box-shadow: none !important;
  z-index: 0;
}

.faqs-wrapper .accordion-button:not(.collapsed) {
  color: #142132;
}

.faqs-wrapper .accordion-body {
  border: none;
  color: #3d434e;
  font-size: 20px;
  line-height: 36px;
  padding: 0 30px;
  position: relative !important;
  -webkit-transform: none !important;
  transform: none !important;
  width: 100%;
  border-radius: 40px;
}

.faqs-wrapper h2 {
  margin: 0;
}

.rate-plan {
  font-family: Grotesc;
  font-weight: 500;
  font-size: 1.25rem;
}

.input-container {
  position: relative;
  display: inline-block;
}

.input-label {
  position: absolute;
  top: 10px;
  left: 16px;
  height: 20px;
  color: #4b5563;
  pointer-events: none;
  transition: all 0.2s ease-out;
  font-size: 14px;
}

.input-label.active {
  top: -8px;
  left: 8px;
  font-size: 12px;
  color: rgb(0, 13, 181);
  background-color: #f4f5f8;
}

.input-field {
  width: 100%;
  height: 40px;
  border: 1px solid #4b5563;
  padding: 12px;
  font-size: 16px;
  outline: none;
  border-radius: 5px;
}

.textarea-field {
  height: 40px;
  min-height: 40px;
  /* add a minimum height of 40px */
  border: 1px solid #4b5563;
  padding: 12px;
  font-size: 16px;
  line-height: 16px;
  outline: none;
  border-radius: 5px;
  overflow-y: hidden;
  resize: none;
}

.textarea-field:hover {
  border: 1px solid #333;
}

.textarea-field:focus {
  border: 2px solid rgb(0, 13, 181);
}

.input-field:hover {
  border: 1px solid #333;
}

.input-field:focus {
  border: 2px solid rgb(0, 13, 181);
}

.diagnose-note {
  font-family: 'Foundation Sans', 'Helvetica Neue';
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.15;
  color: #4b5563;
}

.blue-link {
  color: #0070f3;
  text-decoration: none;
  border-bottom: 1px solid #0070f3;
}

.blue-link:hover {
  color: #004ac0;
  border-bottom-color: #004ac0;
}

.copy-btn {
  border: 1px solid #4b5563;
  border-radius: 5px;
  padding: 8px 16px;
  background-color: transparent;
  color: #555;
  transition: background-color 0.3s ease-in-out;
  cursor: pointer;
}

.copy-btn:hover {
  background-color: #eee;
}

.foot-feedback {
  max-width: 30rem;
}

.feedback-btn {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.75;
  text-transform: uppercase;
  min-width: 64px;
  padding: 6px 8px;
  border-radius: 4px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: rgb(0, 13, 181);
  box-shadow: none;
  font-family: Grotesc;
}

.feedback-btn:hover {
  background-color: #ddd;
}

.navbar-dark .navbar-nav .nav-link:hover {
  color: #4b5563 !important;
}

.navbar-dark .navbar-nav .nav-link.active {
  font-weight: bold;
}

.output .ql-snow {
  border: none !important;
}

.output .ql-editor {
  padding: 0 !important;
}

.article-metadata {
  margin: 0px;
  font-size: 0.875rem;
  line-height: 1.57;
  color: #4b5563;
  display: block;
}

.viewer-metadata {
  margin-right: 32px;
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
}

.viewer-metadata-text {
  margin: 0px;
  font-family: Grotesc;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.57;
  color: #4b5563;
}

.btn-expand {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5;
  cursor: pointer;
}

.btn-expand:hover {
  opacity: 1;
}

.price-grid-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1.5rem;
  max-width: 800px;
  margin: auto;
}

@media screen and (min-width: 800px) {
  .price-grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1.5rem;
    max-width: 800px;
    margin: auto;
  }
}

.price-grid-item {
  box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.5);
  position: relative;
}

.plan-name {
  font-size: 36px;
  font-weight: 600;
}

.plan-name,
.plan-price {
  color: #0074d9;
  line-height: 1.4;
}

.plan-price {
  font-size: 18px;
  font-weight: 400;
}

.plan {
  height: 100%;
  padding: 24px;
}

.plan-list {
  list-style: none;
  text-align: left;
  margin: 2rem;
}

.plan-list,
.plan-list-item {
  margin-left: 0;
  padding-left: 0;
}

.plan-list,
.plan-list-item {
  margin-left: 0;
  padding-left: 0;
}

.plan-list-item {
  margin-bottom: 16px;
}

.plan .status {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 10rem;
  transform: translate(-50%, -50%);
}

.plan-list-description {
  color: #b3b5b8;
}

.text-right {
  text-align: right;
}

.hint-questions-container {
  border-top: 2px solid #000;
  padding-top: 5px;
  margin-top: 5px;
  padding-bottom: 5px;
}

.badge-block {
  padding: 0.35em 0.65em;
  display: block;
  width: 100%;
  font-size: 0.75em;
  cursor: pointer;
  color: rgba(64, 65, 79, 1);
  transition: transform 0.3s, box-shadow 0.3s;
  text-align: left;
  background-color: rgba(255, 255, 255, 1);
  border-color: rgba(0, 0, 0, 0.1);
  border-width: 1px;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.3);
  border-radius: 0.75rem;
  font-weight: 500;
}

.badge-block:hover {
  transform: translateY(-2px);
  background-color: rgba(236, 236, 241, 1);
}

.bottom-dialog {
  position: fixed !important;
  bottom: 0 !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
  margin: 0 !important;
  width: 80% !important;
}

.bg-transparent-dark {
  background-color: rgba(0, 0, 0, 0.3);
}

.bg-transparent {
  background: transparent !important;
  box-shadow: none;
}

.dashed-container {
  border: 2px dashed white;
  /* Set the border to be white and dashed */
  border-radius: 10px;
  /* Set the border-radius for rounded corners */
  padding: 10px;
  /* Add padding to provide some spacing inside the container */
}

.solid-white-border {
  border: 2px solid white !important;
}

.aspect-ratio-placeholder {
  position: relative;
  width: 100%;
}

.aspect-ratio-placeholder::before {
  display: block;
  content: '';
  width: 100%;
  padding-top: 64.98%; /* Example for 16:9 aspect ratio */
}

.badge-placeholder {
  position: relative;
  width: 250px;
}

.badge-placeholder::before {
  display: block;
  content: '';
  width: 250px;
  height: 54px;
}

.chart-placeholder {
  position: relative;
  width: 100%;
}

.chart-placeholder::before {
  display: block;
  content: '';
  width: 100%;
  height: 400px;
}
