@tailwind base;
@tailwind components;
@tailwind utilities;

@media (min-width: 1024px) {
  .lg\:mx-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

@media (min-width: 640px) {
  .sm\:align-middle {
    vertical-align: middle !important;
  }
}

.focus\:visible:focus {
  visibility: visible !important;
}

.group:hover .group-hover\:visible {
  visibility: visible !important;
}

/* a {
  color: #0d6efd;
  text-decoration: underline;
} */

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}

hr:not([size]) {
  height: 1px;
}

.btn-primary {
  background: radial-gradient(circle, #4ea8f1 0, #397de1 50%, #0b50b5 100%);
}

.dark-blue-btn:hover {
  background-color: rgb(0, 7, 100);
}

.dark-blue-btn {
  border: none;
  border-radius: 5px;
  padding: 8px 16px;
  background-color: rgb(0, 13, 181);
  color: white;
  transition: background-color 0.3s ease-in-out;
  cursor: pointer;
}

a {
  word-break: break-word !important;
}

.autoplay-progress {
  position: absolute;
  right: 16px;
  bottom: 16px;
  z-index: 10;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: var(--swiper-theme-color);
}

.autoplay-progress svg {
  --progress: 0;
  position: absolute;
  left: 0;
  top: 0px;
  z-index: 10;
  width: 100%;
  height: 100%;
  stroke-width: 4px;
  stroke: var(--swiper-theme-color);
  fill: none;
  stroke-dashoffset: calc(125.6 * (1 - var(--progress)));
  stroke-dasharray: 125.6;
  transform: rotate(-90deg);
}

.feature-wrapper .content-title {
  align-items: center;
  column-gap: 22px;
  display: flex;
  margin-bottom: 24px;
  margin-right: -100px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.feature-wrapper .title-text {
  color: #142132;
  letter-spacing: 2px;
  line-height: 40px;
  white-space: nowrap;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: 0;
  margin-bottom: 0;
  white-space: pre-wrap;
}

.feature-wrapper .content-subtitle {
  color: #3d434e;
  letter-spacing: 1px;
  line-height: 24px;
  margin-bottom: 20px;
  white-space: pre-wrap;
}

.feature-wrapper .feature-item {
  border-bottom: 1px solid #dedede;
  padding: 20px 0;
}

.feature-wrapper .container {
  padding: 0;
}

.feature-wrapper .feature-row {
  flex-direction: row;
  justify-content: space-between;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}

.feature-row > * {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.feature-wrapper .feature-item:nth-child(2n) {
  background-color: #fbfbfb;
}

.feature-wrapper .feature-item:nth-child(2n) .feature-row {
  flex-direction: row-reverse;
}

.feature-wrapper .desc-list {
  border-right: 1px solid #dedede;
}

.feature-wrapper .feature-item:nth-child(2n) .desc-list {
  border-left: 1px solid #dedede;
  border-right: none;
  padding-left: var(--desc-item-margin-left);
  width: calc(100% + var(--desc-item-margin-left));
}

ol,
ul {
  list-style: none;
}

.feature-wrapper .feature-item:nth-child(2n) .desc-item {
  padding-right: 15px;
}

.feature-wrapper .desc-list li {
  color: #3d434e;
  list-style-type: circle;
}

.feature-wrapper .desc-item {
  cursor: pointer;
  margin-left: var(--desc-item-margin-left);
}

.list-item-active {
  --list-item-selected-offset-left: -40px;
  position: relative;
}

.feature-wrapper .desc-list li::marker {
  color: #6576db;
}

.feature-wrapper .feature-item:nth-child(2n) .desc-item-selected {
  border-radius: 0 30px 30px 0;
}

.list-item-active .list-item-selected {
  background-color: rgba(101, 118, 219, 0.1);
  border-radius: 30px 0 0 30px;
  height: 100%;
  left: var(--list-item-selected-offset-left);
  position: absolute;
  width: calc(100% + var(--list-item-selected-offset-left) * -1);
}

.feature-wrapper .desc-item-text {
  color: inherit;
  padding: 9px 0 10px 8px;
}

.list-item-active .list-item-text {
  color: #6576db !important;
}

.feature-wrapper .feature-video {
  height: 100%;
  padding: 0;
  width: 63%;
}

.feature-wrapper .feature-content {
  max-width: 480px;
  margin: auto;
}

.carousel-inner {
  overflow: hidden;
  position: relative;
  width: 100%;
}

.carousel-item {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  display: none;
  float: left;
  margin-right: -100%;
  position: relative;
  transition: transform 0.6s ease-in-out;
  width: 100%;
}

.feature-wrapper .diagnose-video-item {
  position: relative;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.feature-wrapper .video-chat-item {
  position: relative;
  height: 100%;
  width: 112%;
  max-width: 112%;
  object-fit: cover;
  left: -6%;
}

.feature-wrapper .text-desc {
  color: #3d434e;
  font-size: 20px;
  line-height: 36px;
}

.feature-wrapper .feature-icon {
  color: #6610f2;
}

.feature-wrapper .video-container {
  position: relative;
  width: 100%;
  height: auto;
  overflow: hidden;
}

@media (max-width: 1450px) {
  .feature-wrapper .feature-video {
    width: 60%;
  }

  .feature-wrapper .feature-content {
    width: 37%;
  }
}

@media (max-width: 1200px) {
  .feature-wrapper .feature-row {
    flex-direction: column;
  }

  .feature-wrapper .feature-video {
    width: 100%;
    padding: 0 10px;
  }

  .feature-wrapper .feature-content {
    max-width: unset;
    padding-right: 0;
    width: 100%;
  }
}

@media (min-width: 992px) {
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .feature-wrapper .feature-content {
    margin: 0;
  }

  .feature-wrapper .feature-item {
    border-bottom: 1px solid #dedede;
    padding: 80px 0;
  }
}

.carousel-vertically .active.carousel-item-end,
.carousel-vertically .carousel-item-next:not(.carousel-item-start) {
  transform: translateY(100%);
}

.carousel-vertically .active.carousel-item-start,
.carousel-vertically .carousel-item-prev:not(.carousel-item-end) {
  transform: translateY(-100%);
}

.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  display: block;
}

.bg-gradient-light {
  background-image: linear-gradient(120deg, #7c3aed, #8b5cf6 30%, #f80282);
}

.scrolldown-icon:after {
  content: '\25BC';
  display: inline-block;
  font-size: 20px;
}

.thin-scrollbar::-webkit-scrollbar {
  height: 4px;
}

.markdown-my-0 * {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.bg-chatdata-gradient,
.bg-chatdata-gradient-text {
  background-image: linear-gradient(135deg, #edb43a 10%, #f80296 60%, #306cfd);
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
}

.prose ul {
  list-style-position: inside;
  padding-left: 0;
  padding-right: 0;
}

.prose ol {
  list-style-position: inside;
  padding-left: 0;
  padding-right: 0;
}

.prose li > p {
  display: inline;
}

article .prose {
  max-width: 100%;
}

article .prose pre {
  width: 100%;
}

article .prose a {
  color: #14b8a6;
  font-weight: 600;
  text-decoration: underline;
  text-decoration-color: rgba(20, 184, 166, 0.2);
  transition-property: color, text-decoration-color;
  transition-duration: 0.15s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

article .prose a:hover {
  color: #0d9488;
  text-decoration-color: #14b8a6;
}

article .prose ul {
  list-style-type: disc;
  list-style-position: outside;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  padding-left: 1.625rem;
}

article .prose ol {
  list-style-position: outside;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  padding-left: 1.625rem;
}
